import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { BasicTemplate } from "@templates/BasicTemplate";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { stylesWrapper } from "@theme/commonStyles";
import { breakpoints } from "@theme/breakpoints";
import { BlogCard } from "@components/atoms/BlogCard";
import { ChildImageSharp } from "@interfaces/childImageSharp";
import { Button } from "@components/atoms/Button";
import { Contact } from "@components/molecules/Contact";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { getBlogSlug } from "@utils/getBlogSlug";

const StyledHeader = styled.div`
  ${stylesWrapper};
  margin-top: 220px;
  margin-bottom: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${breakpoints.phoneMax`
    margin-top: 180px;
    margin-bottom: 80px;
  `}

  ${breakpoints.phoneMax`
    flex-direction: column
  `};
`;

const StyledHeadline = styled.h1`
  font-weight: 700;
  font-size: 4.8rem;

  ${breakpoints.laptopMax`
    font-size: 3.4rem;
  `}
`;

const StyledArticlesWrapper = styled.div`
  ${stylesWrapper};
  margin-top: 90px;
  display: flex;
  justify-content: center;
`;

const StyledArticlesInnerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  margin-right: -75px;
  margin-bottom: -75px;

  ${breakpoints.tabletMax`
    margin-right: 0;
    margin-bottom: 0;
  `}
`;

const StyledBlogCard = styled(BlogCard)`
  margin-right: 75px;
  margin-bottom: 75px;

  ${breakpoints.tabletMax`
    margin-right: 0;
    
    :last-of-type {
      margin-bottom: 0; 
    }
  `}

  ${breakpoints.phoneMax`
    margin-bottom: 40px;
  `}
`;

const StyledButton = styled(Button)`
  margin: 120px auto 0;
`;

// @ts-ignore
const StyledDropdown = styled(Dropdown)``;

enum DropdownOption {
  Newest = "0",
  Oldest = "1",
}

const Blog: FC<Props> = ({ data: { allStrapiBlog } }) => {
  const [maxArticles, setMaxArticles] = useState<number>(6);
  const [currentOption, setCurrentOption] = useState<DropdownOption>(
    DropdownOption.Newest
  );
  const [sortedArticles, setSortedArticles] = useState<
    typeof allStrapiBlog.nodes
  >(allStrapiBlog.nodes);
  const { t } = useTranslation();

  useEffect(() => {
    if (currentOption === DropdownOption.Oldest) {
      setSortedArticles(
        allStrapiBlog.nodes
          .sort(
            /*@ts-ignore*/
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
          )
          .slice(0, maxArticles)
      );
    } else if (currentOption === DropdownOption.Newest) {
      setSortedArticles(
        allStrapiBlog.nodes
          .sort(
            /*@ts-ignore*/
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          )
          .slice(0, maxArticles)
      );
    }
  }, [currentOption, maxArticles]);

  return (
    <BasicTemplate
      title={`${t("common:title-blog")}`}
      description={`${t("common:description-blog")}`}
    >
      <StyledHeader>
        <StyledHeadline>Blog</StyledHeadline>
        {/*@ts-ignore*/}
        <StyledDropdown
          options={[
            { label: t("common:newest"), value: DropdownOption.Newest },
            { label: t("common:oldest"), value: DropdownOption.Oldest },
          ]}
          // @ts-ignore
          onChange={(option) => {
            setCurrentOption(option.value as DropdownOption);
          }}
          value={DropdownOption.Newest}
        />
      </StyledHeader>
      <StyledArticlesWrapper>
        <StyledArticlesInnerWrapper>
          {sortedArticles.map(
            (
              { strapi_id, featuredImage, shortDescription, tags, title },
              index
            ) => (
              <StyledBlogCard
                key={index}
                to={getBlogSlug(strapi_id)}
                featuredImage={
                  featuredImage.localFile.childImageSharp.gatsbyImageData
                }
                title={title}
                description={shortDescription.data.shortDescription}
                tags={tags.map(({ name }) => name)}
              />
            )
          )}
        </StyledArticlesInnerWrapper>
      </StyledArticlesWrapper>

      {maxArticles < allStrapiBlog.nodes.length && (
        <StyledButton
          secondary
          onClick={() => setMaxArticles((prevState) => prevState + 6)}
        >
          {t("reviews:see-more-button")}
        </StyledButton>
      )}
      <Contact />
    </BasicTemplate>
  );
};

interface Props {
  data: Data;
}

interface Data {
  allStrapiBlog: {
    nodes: {
      strapi_id: number;
      title: string;
      shortDescription: {
        data: {
          shortDescription: string;
        };
      };
      tags: { name: string }[];
      featuredImage: {
        localFile: ChildImageSharp;
      };
      createdAt: string;
    }[];
  };
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    allStrapiBlog(filter: { locale: { eq: $language } }) {
      nodes {
        strapi_id
        title
        shortDescription {
          data {
            shortDescription
          }
        }
        tags {
          name
        }
        featuredImage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80, placeholder: BLURRED)
            }
          }
        }
        createdAt
      }
    }
  }
`;

export default Blog;
